<template>
  <div class="bar">

    <div class="vbar">

      <div
        class="vbar-list"
        style="padding-top:350px; "
      >

        <el-tooltip
          class="vbar-content-item"
          effect="dark"
          content="会员中心"
          placement="left-start"
        >
          <el-button
            @click="handel(0)"
            :class="{red:(num==0&&show==true)}"
          > <i class="el-icon-user"></i></el-button>
        </el-tooltip>

        <el-tooltip
          class="vbar-content-item"
          effect="dark"
          content="购物车"
          placement="left-start"
        >
          <el-button
            @click="handel(1)"
            :class="{red:(num==1&&show==true)}"
          > <i class="el-icon-shopping-cart-2"></i></el-button>
        </el-tooltip>

        <el-tooltip
          class="vbar-content-item"
          effect="dark"
          content="优惠券"
          placement="left-start"
        >
          <el-button
            @click="handel(2)"
            :class="{red:(num==2&&show==true)}"
          > <i class="el-icon-s-ticket"></i></el-button>
        </el-tooltip>

        <el-tooltip
          class="vbar-content-item"
          effect="dark"
          content="联系客服"
          placement="left-start"
        >
          <el-button
            @click="handel(3)"
            :class="{red:(num==3&&show==true)}"
          > <i class="el-icon-chat-line-round"></i></el-button>
        </el-tooltip>

      </div>
      <transition name="panels">
        <div
          class="vbar-panels"
          v-show="show"
        >
          <!-- 购物车 -->
          <div
            class="shoppcar"
            v-if='num==1'
          >

            <dl>
              <dt>购物车</dt>
              <dd @click="show=false"> 关闭</dd>
            </dl>
            <div class="cont">
              <ul
                v-for="(item, index) in shoplist"
                :key='index'
              >
                <li
                  v-for="(goods, gIndex) in item.carts_list"
                  :key='gIndex'
                >
                  <img
                    :src="$config.baseUrl + goods.files_path"
                    @click="$util.toDetail(goods.shop_type, goods.goods_id )"
                  >
                  <div class="r">

                    <p>
                      {{goods.goods_name}}</p>

                    <p>{{ JSON.parse(goods.option).spec }}</p>
                    <p>
                      <strong v-if="JSON.parse(goods.option).id">
                        ￥{{parseFloat(JSON.parse(goods.option).price).toFixed(2)}}
                      </strong>
                      <strong v-else>
                        ￥{{parseFloat(goods.sales_price / 100).toFixed(2)}}
                      </strong> x {{goods.quantity}}
                    </p>

                    <p>
                      <strong
                        v-if="JSON.parse(goods.option).id"
                        style="color:#ff0036;margin:-4px 0 0 2px;line-height:40px;font-size:16px;"
                      >
                        ￥{{parseFloat(JSON.parse(goods.option).price*goods.quantity).toFixed(2)}}
                      </strong>
                      <strong
                        v-else
                        style="color:#ff0036;margin:-4px 0 0 2px;line-height:40px;font-size:16px;"
                      >
                        ￥{{parseFloat(goods.sales_price / 100*goods.quantity).toFixed(2)}}
                      </strong>
                    </p>
                    <p class="del"><span
                        class='hover'
                        @click="delGoods(goods)"
                      >删除商品</span></p>
                  </div>
                </li>
              </ul>
            </div>

            <div class="last_tr">

              <div class="tr">
                <p> <span class="tr_p1">共</span>
                  <span class="tr_p2"> {{totalQantity}} </span>个
                </p>
                <p> <span class="tr_p1">合计：</span>
                  <span class="tr_p2">{{totalMoney.toFixed(2)}}</span>
                </p>
              </div>

              <div
                class="checked_block "
                @click="toAccount"
              >
                去购物车结算
              </div>

            </div>

          </div>

          <!-- 优惠券 -->

          <div
            class="coupon"
            v-if='num==2'
          >

            <dl>
              <dt>优惠券</dt>
              <dd @click="show=false"> 关闭</dd>
            </dl>
            <div class="cont">
              <div>

                <p class='tit'>平台优惠券</p>
                <div
                  v-if="couponList1.length"
                  style="height:100%;width:100%;  "
                >

                  <div class="coupon">
                    <div
                      class="coupon-list"
                      v-for="(item,index) in couponList1"
                      :key="'2-'+index"
                    >
                      <ul class="item">
                        <li class="left">
                          ￥
                          <span>{{item.get_coupon.money}}</span>
                        </li>
                        <li class="center">
                          <p class="name">{{item.get_coupon.name}}</p>
                          <p>全平台满 {{item.get_coupon.condition}}元可用</p>
                          <p>{{new Date(item.get_coupon.use_start_time).format('yyyy-MM-dd HH:mm')}}至{{new Date(item.get_coupon.use_end_time).format('yyyy-MM-dd HH:mm')}}</p>
                        </li>
                        <li
                          class="right"
                          v-if="item.receive_status==1"
                        >
                          <button
                            class="gray-button"
                            :disabled='true'
                          >已使用</button>
                        </li>
                        <li
                          class="right"
                          v-if="item.receive_status==0"
                        >
                          <button
                            class="red-button"
                            @click="use1(item)"
                          >立即使用</button>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
                <el-empty
                  v-else
                  description="暂无优惠券"
                ></el-empty>
                <p class='tit'>店铺优惠券</p>

                <div v-if="couponList2.length">
                  <div class="coupon">
                    <div
                      class="coupon-list"
                      v-for="(item,index) in couponList2"
                      :key="'2-'+index"
                    >
                      <ul class="item">
                        <li class="left">
                          ￥
                          <span>{{item.get_shop_coupon.money}}</span>
                        </li>
                        <li class="center">
                          <p class="name">{{item.get_shop_coupon.name}}</p>
                          <p>{{item.get_shop_coupon.shop_name}}店铺满 {{item.get_shop_coupon.condition}}元可用</p>
                          <p>{{new Date(item.get_shop_coupon.use_start_time).format('yyyy-MM-dd HH:mm')}}至
                            {{new Date(item.get_shop_coupon.use_end_time).format('yyyy-MM-dd HH:mm')}}
                          </p>
                        </li>

                        <li
                          class="right"
                          v-if="item.receive_status==0"
                        >
                          <button
                            class="red-button"
                            @click="use2(item)"
                          >立即使用</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <el-empty
                  v-else
                  description="暂无优惠券"
                ></el-empty>

              </div>

            </div>

          </div>

        </div>
      </transition>
    </div>

  </div>

</template>
<script>
export default {
  data() {
    return {
      hover: false,
      show: false,
      show2: false,
      num: undefined,
      shoplist: [],
      couponList1: [],
      couponList2: [],
      kefu: ""
    };
  },
  components: {},
  props: {
    shopId: [Number, String]
  },
  watch: {
    "$store.state.cartData"() {
      this.getProuduct();
    },
    shopId: {
      immediate: true,
      handler() {
        if (this.shopId) {
          this.getShop();
        }
      }
    }
  },

  computed: {
    totalQantity() {
      let data = this.shoplist;
      let arr = [];
      data.forEach(item => {
        item.carts_list.forEach(goods => {
          if (goods.checked) {
            arr.push(goods);
          }
        });
      });

      return arr.length;
    },
    totalGoods() {
      let count = 0;
      let data = this.shoplist;
      data.forEach(item => {
        count += item.carts_list.length;
      });
      return count;
    },
    totalMoney() {
      let money = 0;
      let data = this.shoplist;
      data.forEach(item => {
        item.carts_list.forEach(goods => {
          if (!goods.checked) return;
          if (goods.spec_id) {
            money += JSON.parse(goods.option).price * goods.quantity;
          } else {
            money += (goods.sales_price / 100) * goods.quantity;
          }
        });
      });

      return money;
    }
  },

  mounted() {
    this.getCoupon2();
    this.getCoupon1();

    this.getProuduct();
    //获取店铺信息
  },
  methods: {
    //跳转可用店铺优惠券产品页面
    use1(item) {
      this.$router.push({
        path: "/sortProduct",
        query: {
          money: item.get_coupon.money
        }
      });
    },
    //跳转可用店铺优惠券产品页面
    use2(item) {
      this.$router.push({
        path: "/shop",
        query: {
          id: item.get_shop_coupon.shop_id
        }
      });
    },
    getShop() {
      this.$get("seller/shop/" + this.shopId).then(res => {
        if (!res.enabled) {
          this.$router.push("/close");
        }

        this.kefu = res.kefu_qq;
      });
    },
     
    //获取我的优惠券
    getCoupon2() {
      this.$get("home/shop_coupon_receive", {
        member_id: this.$store.state.member.id
      }).then(res => {
        this.couponList2 = res.items.data;
      });
    },
    //获取我的优惠券
    getCoupon1() {
      this.$get("home/coupon_receive", {
        member_id: this.$store.state.member.id
      }).then(res => {
        this.couponList1 = res.items.data;
        console.log(" this.couponList1", this.couponList1);
      });
    },
    //结算
    toAccount() {
      let that = this;

      if (this.$store.state.member.type == 1) {
        if (
          this.$store.state.member.status == 0 ||
          this.$store.state.member.status == 3
        ) {
          this.$alert("您还未通过实名认证", {
            confirmButtonText: "去认证"
          }).then(() => {
            this.$router.push({
              path: "/member-setting",
              query: { activeName: 3 }
            });
          });

          return;
        }
        if (this.$store.state.member.status == 1) {
          this.$alert("您的实名认证审核中", {
            confirmButtonText: "确定"
          }).then(() => {});

          return;
        }
      }

      if (this.$store.state.member.type == 2) {
        if (
          this.$store.state.member.companyStatus == 0 ||
          this.$store.state.member.companyStatus == 3
        ) {
          this.$alert("您还未通过实名认证", {
            confirmButtonText: "去认证"
          }).then(() => {
            this.$router.push({
              path: "/member-setting",
              query: { activeName: 3 }
            });
          });

          return;
        }
        if (this.$store.state.member.companyStatus == 1) {
          this.$alert("您的实名认证审核中", {
            confirmButtonText: "确定"
          }).then(() => {});

          return;
        }
      }

      this.$router.push("/cart");
    },

    handel(index) {
      if (index == 0) {
        this.$router.push("/member-index");
        this.show = false;
        return;
      }
      //  if (index == 2) {
      //   this.$router.push("/member-index");
      //   this.show = false;
      //   return;
      // }
      if (index == 3) {
        window.open(
          "http://wpa.qq.com/msgrd?v=3&uin=" + this.kefu + "&site=qq&menu=yes",
          "_blank"
        );

        this.show = false;
        return;
      }
      if (this.show == true && this.num == index) {
        this.show = false;
      } else {
        this.show = true;
      }

      this.num = index;
    },
    scover() {
      this.hover = true;
    },
    scleave() {
      this.hover = false;
    },
    // 获取购物车里的产品
    getProuduct() {
      let templist = JSON.parse(JSON.stringify(this.shoplist));
      this.shoplist = this.$store.getters.cartList;
      this.shoplist.forEach(shop => {
        let oShop = templist.find(a => a.shop_id == shop.shop_id);
        this.$set(shop, "checked", oShop == null ? true : oShop.checked);

        shop.carts_list.forEach(goods => {
          let oGoods = null;
          if (oShop != null) {
            oGoods = oShop.carts_list.find(a => a.goods_id == goods.goods_id);
          }
          this.$set(goods, "checked", oGoods == null ? true : oGoods.checked);
        });
      });
    },

    // 删除商品
    delGoods(goods) {
      this.$confirm("确认删除该商品吗？", { type: "warning" })
        .then(() => {
          this.$store.dispatch("delCarData", {
            cart_id: goods.cart_id,
            goods_id: goods.goods_id
          });
        })
        .catch(() => {});
    }
  },

  created() {}
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/mixins";
.coupon::after {
  clear: both;
  display: block;
  content: "";
}
.coupon {
  .tit {
    text-align: center;
    line-height: 40px;
  }
  .coupon-list {
    color: #666;
    width: 100%;

    background: #fff;
    border: 1px solid #ddd;
    margin: 1%;
    margin-bottom: 10px;

    .item {
      height: 8rem;
      margin: 5px;
      box-sizing: border-box;
      background: #fff4f2;
      @include space-between;
      .left {
        @include center;

        width: 5rem;
        padding: 5px;
        box-sizing: border-box;
        text-align: left;
        span {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .center {
        text-align: left;

        padding: 0.5rem;
        p {
          width: 100%;
        }
        flex: 1;
        width: 30px;
        text-align: left;
        .name {
          text-align: left;

          font-weight: bold;
          font-size: 12px;
          font-size: 14px;
        }
      }
      .right {
        @include center;
        .red-button {
          width: 22px;
          height: 100%;
          border-radius: 0px;
          @include center;
          background: red;
          color: #fff;
          border: 0;
          font-size: 12px;
        }
        .gray-button {
          width: 100%;
          height: 120px;
          border-radius: 0px;
          @include center;
          background: #aaa;
          color: #fff;
        }
      }
    }
  }
}
dl {
  padding: 0 10px;
  @include space-between;
  line-height: 45px;
  height: 45px;
  border-bottom: 1px solid #eee;
}
.cont {
  padding: 10px;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  background: #f5f5f5;
}
.shoppcar {
  .last_tr {
    padding: 10px;
    border-top: 1px solid #eee;
    @include space-between;
    .checked_block {
      height: 100%;
      line-height: 45px;
      width: 100px;
      text-align: center;
      background: #f22d00;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
  }

  ul {
    li {
      background: #fff;
      padding: 10px;
      margin-top: 10px;
      @include space-between;
      img {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        box-sizing: border-box;
      }
      .r {
        p {
          strong {
            margin: 0 !important;
            line-height: 20px !important;
          }
        }
        p:nth-child(1) {
          text-align: left;

          font-size: 14px;
          color: #757575;
          line-height: 18px;
          overflow: hidden;
          height: 38px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        p:nth-child(4) {
          line-height: 20px;
          height: 20px;
        }
        flex: 1;
        .del {
          text-align: right;
          color: #7a6e6e;
        }
      }
    }
  }
}
.red {
  background: #c81623 !important;
}
::v-deep .el-button {
  border: none !important;
  padding: 0;
  @include center;
  width: 40px;
  height: 35px;
  line-height: 35px;

  background-color: #7a6e6e;
  border-radius: 3px 0 0 3px;
  margin-top: 5px;
  i {
    font-size: 18px;
    color: #fff;
  }

  cursor: pointer;
  text-align: center;
}

.panels-leave-active,
.panels-enter-active {
  transition: all 0.5s ease;
}

.panels-leave-active,
.panels-enter {
  width: 0px !important;
}

.panels-leave,
.panels-enter-active {
  width: 330px;
}

.vbar {
  @include space-between;
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 0;
  height: 100%;

  -webkit-font-smoothing: antialiased;

  .vbar-list {
    width: 5px;
    background-color: #7a6e6e;
    .hover2 {
      width: 34px;
    }
    .vbar-content-item {
      margin-left: -40px;
      .el-button {
      }
    }

    .vbar-ability {
      margin-top: 133px;
    }
  }

  .vbar-panels {
    background: #fff;
    height: 100%;
    width: 330px;
  }

  .hover1 {
    i {
      color: red !important;
    }
    color: red !important;
    background: #fff;
    margin-left: -200px;
    width: 200px !important;

    // animation: tips 1s;
    // -webkit-animation: tips 1s; /* Safari and Chrome */
    // animation-fill-mode: forwards;
    // -webkit-animation-fill-mode: forwards;
  }
  @keyframes tips {
    from {
      width: 34px;
      background: #fff;
    }
    to {
      width: 100px;
      background: #000;
    }
  }

  @-webkit-keyframes tips {
    from {
      width: 34px;
    }
    to {
      width: 100px;
    }
  }
}
</style>
