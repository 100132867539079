<template>
  <div class="themeBg shop_nav">

    <div class="nav">
      <div class="ProgressBoxTool" v-if="shopSort && shopSort.length">
        <div class="processBox">
          <div :class="currentClickNumber > 0 ? 'arrow' : 'arrow arrowOpacity'" @click="fnPrev()" v-if='show'>

            <i class="el-icon-caret-left icon"></i>
          </div>
          <div class="fixedBox" :ref="`fixedBox`">
            <div class="centerScroll" ref="centerScroll" :style="
              `width:${signleWidth *
                shopSort.length}px;transform:translate(${scrollResultWidth}px,0);transition:1s;`
            ">

              <el-menu :default-active="activeIndex" :background-color="backgroundColor" :text-color="textColor" :active-text-color="activeTextcolor" class="el-menu-demo" mode="horizontal"
                style="height:40px;line-height:40px">
                <el-menu-item @click="toShop" index="1" style="height:40px;line-height:40px:color:#fff">首页</el-menu-item>
                <template v-for="(item,index) in shopSort">
                  <template v-if="item.children&&item.children.length">
                    <el-submenu :index="'2-'+item.pid+'-'+item.id" style="height:40px;line-height:40px" :key='index'>
                      <template slot="title" style="height:40px;line-height:40px" @click="toList(item)">{{item.classify_name}}</template>
                      <el-menu-item :index="'2-'+i.pid+'-'+i.id" v-for='(i,idx) in item.children' :key='idx' @click="toList(i)">{{i.classify_name}}</el-menu-item>
                    </el-submenu>
                  </template>
                  <template v-else>
                    <el-menu-item :index="'2-'+item.pid+'-'+item.id" style="height:40px;line-height:40px" :key='index' @click="toList(item)">{{item.classify_name}}</el-menu-item>
                  </template>
                </template>

                <el-menu-item v-if='shopInfo.ysw_member' @click="toShop" index='99' style="height:40px;line-height:40px:color:#fff">
                  <p @mouseover="scover2()" @mouseleave="scleave2()">
                    新闻资讯

                    <transition name="overcat">
                      <div class="B_l overBox2" v-show="Scboxshow2">

                        <ul class="news">
                          <li v-for='(item,index) in newList' :key='index'>
                            <a :href="item.linkurl" target='_blank'> {{item.title}}</a>
                          </li>
                        </ul>
                        <p class="more"><a :href="'https://www.861718.com/com/'+shopInfo.ysw_member+'/news/'"> 查看更多</a></p>
                      </div>
                    </transition>
                  </p>
                </el-menu-item>

              </el-menu>

            </div>
          </div>

          <div :class="noScrollRight ? 'arrow' : 'arrow arrowOpacity'" @click="fnNext(activeName)" v-if='show'>
            <i class="el-icon-caret-right icon r"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

export default {
  data() {
    return {
      show: false,

      currentProgressId: '',
      scrollResultWidth: 0, //transform滚动的距离
      signleWidth: 215, //单个流程的宽度
      activeName: 0,
      currentClickNumber: 0,
      noScrollRight: true,

      activeIndex: '1',
      shopSort: [],
      newList: [],

      boxshow: false,
      Scboxshow: false,
      boxshow2: false,
      Scboxshow2: false,




      name: "",
      shopInfo: {},


      display: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.initgoRightArrow();
      });
    });
    const { pid, catids } = this.$route.query;
    if (pid) this.activeIndex = '2-' + pid;
    if (catids) this.activeIndex += '-' + catids
  },
  components: {

  },
  props: {

    shopId: [Number, String],
    backgroundColor: [String],
    textColor: [String],
    activeTextcolor: [String],

  },
  watch: {
    shopId: {
      immediate: true,
      handler() {
        if (this.shopId) {
          this.getShop();
        }
      }
    }
  },

  methods: {

    scover2() {
      this.Scboxshow2 = true;
    },
    scleave2() {
      this.Scboxshow2 = false;
    },
    getNews() {
      this.$post(
        "https://www.861718.com/api/wap/news.php",
        {
          page: 1,
          act: "list",
          pagesize: 5,
          username: this.shopInfo.ysw_member
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      ).then(res => {
        this.newList = res;

        console.log('this.newList', this.newList)
      });
    },
    scover() {
      this.Scboxshow = true;
    },
    scleave() {
      this.Scboxshow = false;
    },

    toList(item) {
      this.$router.push({
        path: "/shopInner",
        query: {
          shopid: this.shopId,
          pid: item.pid,
          catids: item.id
        }
      });
    },
    //跳转店铺
    toShop() {

      this.$router.push({
        path: "/shop",
        query: { id: this.shopId }
      });
    },


    //获取店铺信息
    getShop() {
      if (!this.shopId) return;
      this.$get("seller/shop/" + this.shopId).then(res => {
        this.shopInfo = res;
        this.getNews(res.ysw_member);
      });
      this.getShopSort(this.shopId);
    },
    getShopSort(id) {
      if (this.shopId) {
        this.$get("seller/shopclassify", { shop_id: this.shopId }).then(res => {
          this.shopSort = res;
          this.$nextTick(() => {
            if (this.$refs.centerScroll?.clientWidth > this.$refs.fixedBox?.clientWidth) {
              this.show = true
            } else {
              this.show = false
            }
          });
        });
      }
    },

    //初始化判断是否可以向右滚动
    initgoRightArrow() {
      if (!this.$refs[`fixedBox`]) return
      const currentScrollWidth = this.$refs[`fixedBox`].clientWidth;
      const canNumber = Math.floor(currentScrollWidth / this.signleWidth); //可以放下的个数
      //如果最后一个流程图标已经展示出来，则停止滚动
      if (this.currentClickNumber + canNumber >= this.shopSort.length) {
        this.noScrollRight = false;
        return;
      }
    },
    //点击上一个
    fnPrev() {
      //如果右点击的次数大于0，才可以左滚
      if (this.currentClickNumber > 0) {
        this.currentClickNumber -= 1;
        this.noScrollRight = true;
        this.fnScrollWidth('reduce');
      } else {
        return false;
      }
    },
    //点击下一个
    fnNext() {
      const currentScrollWidth = this.$refs[`fixedBox`].clientWidth;
      const canNumber = Math.floor(currentScrollWidth / this.signleWidth); //可以放下的个数
      //如果最后一个流程图标已经展示出来，则停止滚动
      if (this.currentClickNumber + canNumber >= this.shopSort.length) {
        return;
      }
      //说明放不下有滚动条
      if (this.shopSort.length > canNumber) {
        this.currentClickNumber += 1;
        if (this.currentClickNumber + canNumber >= this.shopSort.length) {
          this.noScrollRight = false;
        }
        this.fnScrollWidth('add');
      }
    },
    //translate的宽度
    fnScrollWidth(type) {
      let result = 0;
      if (type === 'reduce') {
        result = 215;
      } else if (type === 'add') {
        result = -215;
      } else {
        result = 0;
      }
      this.scrollResultWidth += result;
    }
  },

  computed: {}
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.icon {
  font-size: 20px;
  color: #fff;
}
.top_left {
  display: inline-block;
}

::v-deep .el-divider--vertical {
  height: 80%;
}

.overBox2 {
  width: 300px;
  background: #fff;
  border: 1px solid #eee;
  position: absolute;
  left: 00px;
  top: 45px;
  z-index: 300;
  padding: 20px;
  dl {
    line-height: 30px;
    padding-bottom: 10px;
    dt {
      font-weight: bold;
    }
    i {
      color: red;
    }
  }
  ul {
    padding-top: 10px;
    li {
      @include ellipsis;
      line-height: 30px;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.overBox {
  width: 300px;
  background: #fff;
  border: 1px solid #eee;
  position: absolute;
  left: 00px;
  top: 80px;
  z-index: 300;
  padding: 20px;
  dl {
    line-height: 30px;
    padding-bottom: 10px;
    dt {
      font-weight: bold;
    }
    dd {
      @include row;
      ::v-deep .el-rate__icon {
        line-height: 30px !important;
      }
      ::v-deep .el-rate__text {
        display: inline-block;
        line-height: 30px !important;
        vertical-align: baseline;
      }
    }
  }
  ul {
    padding-top: 10px;
    border-top: 1px dashed #b1aeae;
    li {
      line-height: 30px;
    }
  }
}
.pf {
  td {
    padding: 5px;
  }
  i {
    font-weight: bold;
    color: red;
  }
}
.contact {
  text-align: right;
  line-height: 30px;
  dd {
    margin-left: 30px;
    i {
      font-size: 18px;
      margin-right: 10px;
    }
    display: inline-block;
  }
}
.head {
  position: relative;

  width: 1226px;
  margin: 0 auto;
  box-sizing: border-box;

  background: #fff;

  vertical-align: middle;
  .st {
    @include space-between;
  }
  .row {
    @include row;
  }
  .name {
    img {
      width: 130px;
      height: 50px;
      margin-right: 10px;

      vertical-align: middle;
    }
    .shopInfo {
      .shop_name {
        font-weight: bold;
        font-size: 14px;
      }
      p {
        line-height: 25px;
        height: 25px;
      }
    }
  }
  .button {
    background: #fff;
    width: 100px;
    line-height: 30px;
    height: 30px;

    color: #e94c15;
    border: 1px solid #e94c15;
    border-radius: 5px;

    @include center;
  }
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 40px;
  line-height: 40px;
}
::v-deep .el-menu--horizontal > .el-menu-item {
  color: #fff;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
}
::v-deep .el-menu.el-menu--horizontal {
  border: none;
  background: none;
  color: #fff;
}
::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  // border: none;
  // background: none;
  // color: #fff;
}
::v-deep .el-submenu__title i {
  color: #fff;
}
.home_top {
  padding-top: 20px;

  background: #fff;
}
.shop_nav {
  .nav {
    width: 1226px;
    margin: 0 auto;
  }
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.sort {
  height: 38px;
  line-height: 38px;
  background: #757575;
  width: 220px;
  color: #fff;
  margin: 10px 0 0 0;
  padding: 0 0 0 10px;
  display: inline-block;
}
.com {
  position: relative;
  box-sizing: border-box;

  width: 1226px;
  margin: 0 auto;

  .logo_img {
    margin-left: 50px;
    width: 200px;

    img {
      width: 100%;
    }
  }

  .Search_Goods {
    position: absolute;
    left: 345px;
    top: 10px;
    overflow: hidden;
    width: 615px;
  }

  .SG_ul {
    :nth-child(n + 2) {
      padding-left: 10px;
    }

    li {
      float: left;
      font-size: 14px;
      padding: 5px 0 0 0;
      color: #a8a8a8;
    }
  }

  .com_ul {
    height: 20px;
    width: 800px;
    display: inline-block;
    margin-left: 20px;
    font-weight: bold;
    font-size: 16px;

    :hover {
      color: red;
      cursor: pointer;
    }

    :nth-child(n + 2) {
      margin-left: 40px;
    }

    li {
      float: left;
      font-size: 16px;

      a {
        font-size: 16px;
        color: #222;
      }

      i {
        font-size: 16px;
      }
    }
  }
}
.Search_Goods {
  position: absolute;
  left: 345px;
  top: 70px;
  overflow: hidden;
  width: 615px;
}

.Shopping_Cart {
  position: absolute;
  height: auto;
  right: 0px;
  top: 70px;
  cursor: pointer;
  width: 200px;

  .overBox {
    position: absolute;
    right: 0px;
    top: 40px;
    z-index: 3000000;
    width: auto;
    height: 300px;
    overflow: hidden;
  }

  .overcat-leave-active,
  .overcat-enter-active {
    transition: all 0.5s ease;
  }

  .overcat-leave-active,
  .overcat-enter {
    height: 0px !important;
  }

  .overcat-leave,
  .overcat-enter-active {
    max-height: 320px;
  }

  p {
    width: 160px;
    float: right;
    border: 2px solid #e94c15;
    padding: 8px 18px;
    background: #e94c15;

    i {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      margin-right: 5px;

      span {
        width: 15px;
        height: 15px;
        margin-left: 10px;
        line-height: 15px;
        text-align: center;
        background: #db2726;
        color: #fff;
        border-radius: 50%;
      }
    }

    span {
      color: #fff;
      font-weight: bold;
    }
  }
}

.box {
  width: 100%;
  height: 50px;
  background-color: #e94c15;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 111111;

  .box_center {
    height: 50px;
    width: 1226px;
    margin: 0 auto;

    // background: yellow;
    // line-height: 50px;
    h1 {
      float: left;
      color: #fff;
      font-size: 22px;
      margin-top: 5px;
    }
  }
}
.mybox-leave-active,
.mybox-enter-active {
  transition: all 0.5s ease;
}

.mybox-leave-active,
.mybox-enter {
  height: 0px !important;
}

.mybox-leave,
.mybox-enter-active {
  height: 50px;
}
// ::v-deep .el-rate__text{display: inline-block;
//     position: relative;    line-height: 35px;
//     width: 30px;
//      vertical-align: middle;}
.el-menu--horizontal > .el-menu-item.is-active {
  background: rgba($color: rgb(20, 20, 20), $alpha: 0.3) !important;
}
.news {
  li {
    color: #757575;
    border-top: 1px dashed #b1aeae;
  }
}

.processBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .l {
    float: left;
  }
  .r {
    float: right;
  }
  .arrow {
    width: 40px;
    cursor: pointer;
  }
  .arrowOpacity {
    cursor: default;
    opacity: 0.4;
  }
  .fixedBox {
    flex: 1;
    overflow: hidden;
  }
  .centerScroll {
    // flex: 1;
    box-sizing: border-box;
    white-space: nowrap;
    // width: calc(100% - 120px);
    // overflow-x: auto;
    .signleTab {
      width: 215px;
      position: relative;
      display: inline-block;
      .leftIcon {
        width: 150px;
        text-align: center;
        cursor: pointer;
        & > .pregressIcon {
          width: 60px;
          height: 60px;
        }
      }
      & > .arrowSquare {
        position: absolute;
        top: 25px;
        right: 0;
      }
    }
  }
}
</style>
