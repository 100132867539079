<template>
  <div class="home_top">

    <el-row class="head">
      <el-col class="name" :span='12'>
        <div class="top_left" @mouseover="scover()" @mouseleave="scleave()">
          <div class="row">
            <img @mouseover="scover()" @mouseleave="scleave()" v-if='shopInfo.shoplogo' @click="$router.push({path:'/shop',query:{id:shopInfo.shop_id}})"
              :src="$config.baseUrl + shopInfo.shoplogo.files_path" />
            <img v-else @click="$router.push('/')" :src=" defaultImg" />

            <div class="shopInfo">
              <p class="shop_name">
                {{ shopInfo.shop_name }}

              </p>

              <el-rate v-model="value1" disabled text-color="#ff9900" score-template="{value}">
              </el-rate>
            </div>
          </div>
          <div class="st pf">
            <table>
              <tr>
                <td>描述<i> {{shopInfo.shop_score}}</i></td>
                <td>服务 <i> {{shopInfo.shop_serscore}}</i></td>
                <td>物流<i> {{shopInfo.shop_shippscore}}</i></td>
                <!-- <td>粉丝数：{{shopInfo.shop_collect}}</td> -->
              </tr>
            </table>

          </div>

          <transition name="overcat">

            <!--  -->
            <div class="B_l overBox" v-show="Scboxshow">
              <dl>
                <dt>店铺动态评分: </dt>
                <dd><i>描述相符： </i>

                  <el-rate v-model="shop_score" disabled text-color="#ff9900" score-template="{value}">
                  </el-rate>

                </dd>

                <dd><i>物流服务：</i>

                  <el-rate v-model="shop_shippscore" disabled text-color="#ff9900" score-template="{value}">
                  </el-rate>
                </dd>
                <dd><i>售后服务：</i>

                  <el-rate v-model="shop_serscore" disabled text-color="#ff9900" score-template="{value}">
                  </el-rate>
                </dd>
              </dl>

              <ul>

                <li>证件信息 <img style="width: 25px;height:25px;margin-left:10px" src="../assets/images/zj.png" @click="$router.push({path:'shop-level',query:{id:shopId}})">
                </li>
              </ul>

            </div>
          </transition>

        </div>
      </el-col>

      <el-col :span='12'>
        <HomeSerach style="width:100%" :id='shopId' :type='1' />

        <dl class="contact">
          <dd>

            <span @click="follow" v-if="icon2 == false"> <i class="el-icon-star-off"></i>关注店铺</span>
            <span @click="follow" v-else> <i class="el-icon-star-on themefont"></i>店铺已关注</span>
          </dd>

          <dd><i class="el-icon-s-custom themefont"></i>
            <a style="color: red" target="_blank" :href="'http://wpa.qq.com/msgrd?v=3&uin='+shopInfo.kefu_qq+'&site=qq&menu=yes'">

              联系客服

            </a>

          </dd>
        </dl>

      </el-col>
    </el-row>
    <shopNav backgroundColor='#fe6925' textColor='#fff' activeTextcolor='#fff' :shopId='shopId'></shopNav>

  </div>

</template>
<script>
import HomeSerach from "@/components/home-search";
import shopNav from "@/components/shop-nav";


export default {
  data() {
    return {
      activeIndex: '1',
      shop_score: 5.0,
      shop_serscore: 5.0,
      shop_shippscore: 5.0,
      newList: [],
      value1: 5,
      shop_name: "",
      icon2: false,
      boxshow: false,
      Scboxshow: false,
      boxshow2: false,
      Scboxshow2: false,
      NumData: "",
      carData: "",
      lists: [],

      defaultImg: require("@/assets/images/logo.png"),
      star: 2,
      shopSort: [],
      show1: false,

      searchData: {
        sale_num_pr: undefined,
        price_pr: undefined
      },

      num: 0,
      num2: 1,
      listIndex: 0,
      name: "",
      goodList: [],
      shopInfo: {},
      couponList: "",
      car: false,

      tipsQuantity: 0,

      display: false
    };
  },
  mounted() {
    const { pid, catids } = this.$route.query;
    if (pid) this.activeIndex = '2-' + pid;
    if (catids) this.activeIndex += '-' + catids
  },
  components: {
    HomeSerach,
    shopNav
  },
  props: {
    shopId: [Number, String]
  },
  watch: {
    shopId: {
      immediate: true,
      handler() {
        if (this.shopId) {
          this.getShop();
          this.findFollow();
        }
      }
    }
  },

  methods: {
    scover2() {
      this.Scboxshow2 = true;
    },
    scleave2() {
      this.Scboxshow2 = false;
    },
    getNews() {
      this.$post(
        "https://www.861718.com/api/wap/news.php",
        {
          page: 1,
          act: "list",
          pagesize: 5,
          username: this.shopInfo.ysw_member
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      ).then(res => { this.newList = res; });
    },
    scover() {
      this.Scboxshow = true;
    },
    scleave() {
      this.Scboxshow = false;
    },

    toList(item) {
      this.$router.push({
        path: "/shopInner",
        query: {
          shopid: this.shopId,
          pid: item.pid,
          catids: item.id
        }
      });
    },
    //跳转店铺
    toShop() {
      this.$router.push({
        path: "/shop",
        query: { id: this.shopId }
      });
    },
    //查看是否关注店铺
    findFollow() {
      if (!this.shopId) return;
      this.$post("home/collectshop/find", {
        shop_id: this.shopId,
        member_id: this.$store.state.member.id,
        collect_shop_name: this.shopInfo.shop_name
      }).then(res => {
        if (res.collect_id) {
          this.icon2 = true;
        }
      });
    },
    // 关注店铺
    follow() {
      if (this.icon2 == false) {
        this.$post("home/collectshop", {
          shop_id: this.shopId,
          member_id: this.$store.state.member.id,
          collect_shop_name: this.shopInfo.shop_name
        }).then(res => {
          this.icon2 = true;
        });
      } else {
        this.$post("home/collectshop/del", {
          shop_id: this.shopId,
          member_id: this.$store.state.member.id
        }).then(res => {
          this.icon2 = false;
          this.$message.success("已取消关注");
        });
      }
    },
    //获取店铺信息
    getShop() {
      console.log("this.shopId3", this.shopId);
      if (!this.shopId) return;
      this.$get("seller/shop/" + this.shopId).then(res => {
        this.shopInfo = res;
        console.log("   this.shopInfo", this.shopInfo);

        this.shop_name = this.shopInfo.shop_name;
        this.value1 = parseFloat(this.shopInfo.shop_score);
        this.shop_score = parseFloat(this.shopInfo.shop_score);
        this.shop_serscore = parseFloat(this.shopInfo.shop_serscore);
        this.shop_shippscore = parseFloat(this.shopInfo.shop_shippscore);

        this.searchData.shopid = res.shop_id;
        this.getNews(res.ysw_member);
      });
      this.getShopSort(this.shopId);
    },
    getShopSort(id) {
      if (this.shopId) {
        this.$get("seller/shopclassify", { shop_id: this.shopId }).then(res => {
          this.shopSort = res;
        });
      }
    },
  },

  computed: {}
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.top_left {
  display: inline-block;
}

::v-deep .el-divider--vertical {
  height: 80%;
}

.overBox2 {
  width: 300px;
  background: #fff;
  border: 1px solid #eee;
  position: absolute;
  left: 00px;
  top: 45px;
  z-index: 300;
  padding: 20px;
  dl {
    line-height: 30px;
    padding-bottom: 10px;
    dt {
      font-weight: bold;
    }
    i {
      color: red;
    }
  }
  ul {
    padding-top: 10px;
    border-top: 1px dashed #b1aeae;
    li {
      @include ellipsis;
      line-height: 30px;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.overBox {
  width: 300px;
  background: #fff;
  border: 1px solid #eee;
  position: absolute;
  left: 00px;
  top: 80px;
  z-index: 300;
  padding: 20px;
  dl {
    line-height: 30px;
    padding-bottom: 10px;
    dt {
      font-weight: bold;
    }
    dd {
      @include row;
      ::v-deep .el-rate__icon {
        line-height: 30px !important;
      }
      ::v-deep .el-rate__text {
        display: inline-block;
        line-height: 30px !important;
        vertical-align: baseline;
      }
    }
  }
  ul {
    padding-top: 10px;
    border-top: 1px dashed #b1aeae;
    li {
      line-height: 30px;
    }
  }
}
.pf {
  td {
    padding: 5px;
  }
  i {
    font-weight: bold;
    color: red;
  }
}
.contact {
  text-align: right;
  line-height: 30px;
  dd {
    margin-left: 30px;
    i {
      font-size: 18px;
      margin-right: 10px;
    }
    display: inline-block;
  }
}
.head {
  position: relative;

  width: 1226px;
  margin: 0 auto;
  box-sizing: border-box;

  background: #fff;

  vertical-align: middle;
  .st {
    @include space-between;
  }
  .row {
    @include row;
  }
  .name {
    img {
      width: 130px;
      height: 50px;
      margin-right: 10px;

      vertical-align: middle;
    }
    .shopInfo {
      .shop_name {
        font-weight: bold;
        font-size: 14px;
      }
      p {
        line-height: 25px;
        height: 25px;
      }
    }
  }
  .button {
    background: #fff;
    width: 100px;
    line-height: 30px;
    height: 30px;

    color: #e94c15;
    border: 1px solid #e94c15;
    border-radius: 5px;

    @include center;
  }
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 40px;
  line-height: 40px;
}
::v-deep .el-menu--horizontal > .el-menu-item {
  color: #fff;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
}
::v-deep .el-menu.el-menu--horizontal {
  border: none;
  background: none;
  color: #fff;
}
::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  // border: none;
  // background: none;
  // color: #fff;
}
::v-deep .el-submenu__title i {
  color: #fff;
}
.home_top {
  padding-top: 20px;

  background: #fff;
}
.shop_nav {
  .nav {
    width: 1226px;
    margin: 0 auto;
  }
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.sort {
  height: 38px;
  line-height: 38px;
  background: #757575;
  width: 220px;
  color: #fff;
  margin: 10px 0 0 0;
  padding: 0 0 0 10px;
  display: inline-block;
}
.com {
  position: relative;
  box-sizing: border-box;

  width: 1226px;
  margin: 0 auto;

  .logo_img {
    margin-left: 50px;
    width: 200px;

    img {
      width: 100%;
    }
  }

  .Search_Goods {
    position: absolute;
    left: 345px;
    top: 10px;
    overflow: hidden;
    width: 615px;
  }

  .SG_ul {
    :nth-child(n + 2) {
      padding-left: 10px;
    }

    li {
      float: left;
      font-size: 14px;
      padding: 5px 0 0 0;
      color: #a8a8a8;
    }
  }

  .com_ul {
    height: 20px;
    width: 800px;
    display: inline-block;
    margin-left: 20px;
    font-weight: bold;
    font-size: 16px;

    :hover {
      color: red;
      cursor: pointer;
    }

    :nth-child(n + 2) {
      margin-left: 40px;
    }

    li {
      float: left;
      font-size: 16px;

      a {
        font-size: 16px;
        color: #222;
      }

      i {
        font-size: 16px;
      }
    }
  }
}
.Search_Goods {
  position: absolute;
  left: 345px;
  top: 70px;
  overflow: hidden;
  width: 615px;
}

.Shopping_Cart {
  position: absolute;
  height: auto;
  right: 0px;
  top: 70px;
  cursor: pointer;
  width: 200px;

  .overBox {
    position: absolute;
    right: 0px;
    top: 40px;
    z-index: 3000000;
    width: auto;
    height: 300px;
    overflow: hidden;
  }

  .overcat-leave-active,
  .overcat-enter-active {
    transition: all 0.5s ease;
  }

  .overcat-leave-active,
  .overcat-enter {
    height: 0px !important;
  }

  .overcat-leave,
  .overcat-enter-active {
    max-height: 320px;
  }

  p {
    width: 160px;
    float: right;
    border: 2px solid #e94c15;
    padding: 8px 18px;
    background: #e94c15;

    i {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      margin-right: 5px;

      span {
        width: 15px;
        height: 15px;
        margin-left: 10px;
        line-height: 15px;
        text-align: center;
        background: #db2726;
        color: #fff;
        border-radius: 50%;
      }
    }

    span {
      color: #fff;
      font-weight: bold;
    }
  }
}

.box {
  width: 100%;
  height: 50px;
  background-color: #e94c15;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 111111;

  .box_center {
    height: 50px;
    width: 1226px;
    margin: 0 auto;

    // background: yellow;
    // line-height: 50px;
    h1 {
      float: left;
      color: #fff;
      font-size: 22px;
      margin-top: 5px;
    }
  }
}
.mybox-leave-active,
.mybox-enter-active {
  transition: all 0.5s ease;
}

.mybox-leave-active,
.mybox-enter {
  height: 0px !important;
}

.mybox-leave,
.mybox-enter-active {
  height: 50px;
}
// ::v-deep .el-rate__text{display: inline-block;
//     position: relative;    line-height: 35px;
//     width: 30px;
//      vertical-align: middle;}
</style>
